export const environment = {
  title: 'Hybrid books',
  type: 'prod',
  production: true,
  backendApiUrl: 'https://hybridbooks.arbookslibrary.com/api/',
  whitelistedDomains: ['hybridbooks.arbookslibrary.com'],
  blacklistedRoutes: ['hybridbooks.arbookslibrary.com/admin/'],
  backendApiVersion: 'v1',

};



